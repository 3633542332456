.logout-dropdown-item.dropdown-item {
  width: unset;
}
.logout-dropdown-item.dropdown-item:hover .logout-parent {
  border-radius: 10%;
  background-color: rgb(230, 230, 230);
}

.fa-cog:hover {
    animation: rotate 1s 1;
}
@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

button:disabled {
  opacity: 0.5;
}
