.details {
    font-weight: 500;
    padding: 2px;
    font-size: 14px;
}

.truncate {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #3b3be7;
    text-decoration: underline;
}

.brand-error {
    color: #ff2929;
    margin-bottom: 10px;
    font-size: 15px;
}

.recommend{
    background: #3851c3;
    color: white;
    padding-right: 5px;
    padding-left: 5px;
}

.details-padding {
    margin-left: 10px;
    background: #3851c3;
    padding-left: 10px;
    padding-right: 10px;
    align-self: center;
    color: white;
    font-size: 12px;
    border-radius: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    cursor: pointer;
}

.dashboard-chart-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    max-width: 100% !important;
}

/* 
.filter-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center
}  
.filter-element {
    width: 350px;
}
*/

.filter-box{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px,1fr));
    justify-content: space-between;
    grid-gap: 20px;
}


.react-datepicker-wrapper, .react-datepicker__input-container, .react-datepicker__input-container input {
    width: 100%;
}

.filter-input {
    display: flex;
    width: 100%;
}

.HeaderList{
    padding: 15px !important;
}
.patient-tbl-nav-box {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 1024px) {
    .patient-tbl-nav-box {
        flex-direction: column;
    }
    .patient-tbl-action-btn {
        margin-top: 10px;
    }
}

@media (max-width: 768px) {
    .row-body {
        display: flex;
        margin-bottom: 10px;
    }
    .column-lbl {
        min-width: 130px;
        max-width: 130px;
    }
}

@media (max-width: 425px) {
    .filter-element {
        width: 100%;
    }
    .column-lbl {
        min-width: 95px;
        max-width: 95px;
    }
}
