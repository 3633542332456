:root {
    --page-background-color: #f4f6f9;

    --color-primary: #2a3142;
    --color-primary-hover: #3f4a62;
    --color-green: #A1B759;
    --color-green-hover: #96ab53;
    --color-gray: #454951;
    --color-light-gray: #999999;
    --color-gray-hover: #4f535c;
    --color-dark-blue: #29265B;
    --color-dark-blue-hover: #2f2c68;
    --color-extreme-light-blue: rgba(210, 220, 255, .20);
    --color-light-blue-low-opacity: rgba(72, 154, 247, 0.4);
    --color-super-light-blue: #cce5ff;
    --color-blue: #1773B7;
    --color-blue-hover: #1e7cc3;
    --color-lightblue: #47bce6;
    --color-lightblue-hover: #7ccae6;
    --color-darkred: #8B0000;

    /* Update to previous bootstrap colors */
    --bs-success-rgb: 67, 158, 58 !important;
    --bs-success: rgb(67, 158, 58) !important;
    --bs-info-rgb: 71, 159, 184 !important;
    --bs-info: rgb(71, 159, 184) !important;
    --bs-warning-rgb: 246, 197, 17 !important;
    --bs-warning: rgb(246, 197, 17) !important;
}

body {
    background: var(--page-background-color) !important;
}

.MuiMenuItem-root.Mui-selected { /* material ui dropdown selected item bg color */
    background-color: var(--color-light-blue-low-opacity) !important;
}

.badge.bg-warning {
    color: black !important; /* black text color for a better contrast */
}
    
.table-card {
    border-color: white !important;
}

.rdt_TableBody .rdt_TableRow button[aria-label="Expand Row"],
.rdt_TableBody .rdt_TableRow button[aria-label="Collapse Row"] {
    color: rgba(0,0,0,.54);
}

.rdt_TableBody .rdt_TableRow button[aria-label="Expand Row"]:focus,
.rdt_TableBody .rdt_TableRow button[aria-label="Collapse Row"]:focus {
    background-color: rgba(0,0,0,.12);
}

.filter-labels {
    color: var(--color-primary);
    font-weight: 500;
}

.formulary {
    padding: 20px;
    margin-bottom: 20px;
}

.loading {
    width: 100px;
    height: 100px;
    display: inline-block;
}

.loading::after {
    content: " ";
    display: block;
    width: 90px;
    height: 90px;
    margin: 8px;
    border-radius: 50%;
    border: 10px solid #ccc;
    border-color: #ccc transparent #ccc transparent;
    animation: loading-circle 1.2s linear infinite;
}

.mouseoverdark:hover {
    filter: brightness(75%);
}

@keyframes loading-circle {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .ant-input-group-addon {
    background-color: #46516b;
    color: #fff;
  }
.hoverover:hover{
    -webkit-filter: brightness(70%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}

.fa-info-circle {
    opacity: 0.5;
}

/* *fonts */
.font10 {
	font-size: 10px;
}
.font12 {
	font-size: 12px;
}
.font14 {
	font-size: 14px;
}
.font16 {
	font-size: 16px;
}
.font18 {
	font-size: 18px;
}
.font24 {
	font-size: 24px;
}
.font34 {
	font-size: 34px;
}
.font40 {
	font-size: 40px;
}
.font48 {
	font-size: 48px;
}
.font-normal {
	font-weight: normal !important;
}
.font-medium {
	font-weight: 500 !important;
}
.font-bold {
	font-weight: 700 !important;
}
*:focus {
    outline: none !important;
}

/* * bootStrap card */
.card {
    box-shadow: 0 0 1px rgba(0,0,0,.125),0 1px 3px rgba(0,0,0,.2);
    margin-bottom: 1rem;
    background: #fff;
}
.card-header {
    background-color: transparent !important;
    border-bottom: 1px solid rgba(0,0,0,.125);
}
.card-header-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.card-title-custom { 
    color:var(--color-primary);
    margin-bottom: 0px;
}

/* *bootstrap  button*/
.btn-blue {
    color: #fff;
    background-color: var(--color-blue) !important;
}
.btn-blue:hover {
    background-color: var(--color-blue-hover) !important;
}
.btn-purple {
    background-color: #FF0080 !important;
    color: white !important;
}
.btn-lightblue {
    background-color: var(--color-lightblue) !important;
}
.btn-lightblue:hover {
    background-color: var(--color-lightblue-hover) !important;
}
.btn-green {
    background-color: var(--color-green) !important;
    color: #fff !important;
}
.btn-green:hover {
    background-color: var(--color-green-hover) !important;
}
.btn-gray {
    background-color: var(--color-gray) !important;
    color: #fff !important;
}
.btn-gray:hover {
    background-color: var(--color-gray-hover) !important;
}
.btn-primary {
    color: #fff;
    background-color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
}
.btn-primary:hover {
    background-color: var(--color-primary-hover) !important;
}
.btn.focus, .btn:focus {
    outline: 0;
    box-shadow: unset !important;
}

.btn-outline-primary {
    color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
}

.btn-outline-primary:hover {
    color: #fff !important;
    background-color: var(--color-primary) !important;
    border-color: var(--color-primary) !important;
}

@media (max-width: 768px) {
    .pressureMarginMobile {
      margin-top: 8px;
    }
  }

/* *react select */
.select-outline-danger .react-select__control {
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 3px rgb(213 0 0 / 60%);
}

/* * custom switch */
.custom-control-input:checked~.custom-control-label::before{
    color: #fff !important;
    border-color: var(--color-primary) !important;
    background-color: var(--color-primary) !important;
}
.custom-switch label {
    width: 100%;
}

/* *ad-hoc section */
.ad-hoc-page-head {
    padding: 10px 0px 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.select-all-switch{
    color: var(--color-primary);
    font-weight: bold;
}
.card-select-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-select-all{
    margin-right: 15px;
}
.adhoc-subgroup {
    margin-bottom: 1rem !important;
}
.adhoc-subgroup-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    width: 100%;
    font-weight: bold;
    text-transform: capitalize;
}

/* *Analytics chart dropdown */
.chart-dropdown:not(.ant-select-disabled):hover .ant-select-selector {
    /* border-color: var(--color-primary); */
    border-right-width: 1px !important;
}
.chart-dropdown .ant-select-arrow{
    user-select: none;
    height: 100%;
    top: 6px;
    padding: 9px;
    right: 0;
    width: 30px;
    background: var(--color-primary);
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.chart-dropdown .ant-select-arrow > span{
    color: #fff;
}
.chart-dropdown .ant-select-selection-item {
    font-weight: 600;
}

.processing-loading {
    cursor: wait;
}

a[class*="link"] {
    text-decoration: none;
}
a[class*="link"]:hover {
    cursor: pointer;
}
a.link-primary:hover {
    text-decoration: underline !important;
}
a[disabled] {
    cursor: not-allowed;
}

.react-tooltip {
    z-index: 1;
}
