.navbar-container {
    display: flex;
    justify-content: space-between;
    height: 85px;
}

.sg-navbar {
    height: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    flex-flow: row !important;
}

.modal-custom-md {
    max-width: 600px !important;
}