.circle-loader {
  &__dot {
    width: 13px;
    height: 13px;
    background: #3ac;
    border-radius: 100%;
    display: inline-block;
    animation: slide 1.1s infinite;
    
    &:nth-child(1) {
      animation-delay: 0.1s;
      background: rgb(73, 165, 199);
    }
    
    &:nth-child(2) {
      animation-delay: 0.2s;
      background: rgb(98, 160, 196);
    }
    
    &:nth-child(3) {
      animation-delay: 0.3s;
      background: rgb(151, 168, 201);
    }
    
    &:nth-child(4) {
      animation-delay: 0.4s;
      background: rgb(191, 159, 197);
    }
    
    &:nth-child(5) {
      animation-delay: 0.5s;
      background: rgb(245, 160, 198);
    }
  }
}

@keyframes slide {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(1.6);
  }
  100% {
    transform: scale(1);
  }
}
