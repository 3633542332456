.AlertTypeContainer{
    display: flex;
    flex-direction: column;
    span{
        margin: 0.5em 0;
    }
}
.ResolvedStatus{
    display: flex;
    align-items: center;
    i{
        font-size: 1.5em;
        color: green;
        margin: 0 0.5em;
    }
}
.CaretDown{
    font-size: 2em;
    height: 0.5em;
}
