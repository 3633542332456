.recommendationSelectRow {
	padding: 10px;
	border-radius: 10px;
	background-color: #f0f0ff;
}
.recommendationSelectRowNoHover {
	padding: 10px;
	border-radius: 10px;
	background-color: #f0f0ff;
}

.recommendationSelectRow:hover {
	background-color: #e0e0ff;
}

.recommendationSelectRow, .recommendationSelectRowNoHover {
	display: flex;
	margin-bottom: 20px;
	align-items: center;
}

.custom-radios input[type=radio] {
	display: none;
}

.cardScroll{
	max-height: 325px;
	min-height: 350px;
	overflow-y: scroll;
	background-color: #fff;
	padding: 0.5em !important;
}

.replyButtons{
	background-color: #e9ecef;
	margin-top: 16px;
}

.ant-collapse-content > .ant-collapse-content-box{
	background-color: #e9ecef;
}

.ChatPanelContainer {
	border-radius: 10px;
}

.ChatPanel{
	background-color: #e9ecef;
}

/* *chinstrap popup/info */
.radio-icon {
	opacity: 0.7;
}
.radio-icon:hover {
	opacity: 1;
}
.recommendedMasks-container .mask-info {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
}
.recommendedMasks-container .mask-check {
	display: flex;
	align-items: center;
	justify-content: start;
}

.checkbox_container .form-check-input {
	border: 1px solid var(--color-primary);
}

.tabpane-content {
	margin-top: 2rem;
}
.modal-lg{
	max-width: 865px !important;
}
.modal-xl{
	max-width: 1140px !important;
}

@media only screen and (max-width: 1000px) {
	.tabpane-content {
		padding: 0 !important;
	}
	.tabpane-content .uploadDocCard {
		padding: 10px !important;
	}
	.patientDocTable {
		padding: 0 !important;
	}
}

.antCollapseNoPadding > .ant-collapse-content > .ant-collapse-content-box{
	padding: 1px
}

.recommendationSelectRowDisabled {
	padding: 10px;
	border-radius: 10px;
	background-color: #d8d8e3;
}

.text-disabled {
	color: gray;
}

.numberInput__container {
	display: flex;
	justify-content: space-between;
	gap: 5px;
}

.pressure-radio-btn-label .MuiFormControlLabel-label {
	line-height: 0.8;
}