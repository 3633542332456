.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    width: 90px;
    height: calc(1.5em + .75rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}
