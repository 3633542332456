.background-login {
    background-color: #153d6f !important;
}

.login-text {
    color: white !important;
}

.account-pages.processing * {
    cursor: progress !important;
}