.sgtooltip {
    color: #B0B0B0;
    margin: 12px;
    font-size: small;
}

.sgtooltip .sgtooltiptext {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  width: 390px;
  right: 10px;
  padding: 12px;
  border-radius: 6px;
  color: #fff;
  background-color: black;
}

.sgtooltip:hover .sgtooltiptext {
  visibility: visible;
}

.PhoneInput {
  position: relative;
}
/* we apply bootstrap's input styling to keep consistency */
.PhoneInput .PhoneInputInput {
  padding: .375rem .75rem;
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  overflow: visible;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.PhoneInput .PhoneInputInput:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
/* Add styling for success, error and loading states */
.PhoneInput.success .PhoneInputInput {
  border: #28a745 1px solid;
}
.PhoneInput.success::after {
  position: absolute;
  right: .6rem;
  color: var(--bs-success);
  font-weight: bold;
  content: "\f00c";
  font-family: "Font Awesome\ 5 Free";
}

.PhoneInput.error .PhoneInputInput {
  border: var(--bs-danger) 1px solid;
}
.PhoneInput.error::after {
  position: absolute;
  top: 9px;
  right: .6rem;
  /* content: "\f06a";  */
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23dc3545' class='bi bi-exclamation-circle' viewBox='0 0 16 16'%3E%3Cpath stroke='%23dc3545' stroke-width='0.4' d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/%3E%3Cpath stroke='%23dc3545' stroke-width='0.4' d='M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z'/%3E%3C/svg%3E");
}
.PhoneInput.twilioLookupLoading::after {
  position: absolute;
  right: .6rem;
  color: var(--bs-dark);
  font-weight: bold;
  content: "\f110";
  font-family: "Font Awesome\ 5 Free";
  animation: spin 1s infinite linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}