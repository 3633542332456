$sidebar-width: 225px;
$sidebar-toggled-width: 40px;

#wrapper {
  padding-left: 0;
  transition: all 0.5s ease;
  position: relative;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: $sidebar-width;
  width: 0;
  height: 100%;
  margin-left: -$sidebar-width;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  transition: all 0.5s ease;
  box-shadow: 0 14px 28px rgba(0,0,0,.25),0 10px 10px rgba(0,0,0,.22)!important;
}

#wrapper.toggled #sidebar-wrapper {
  width: $sidebar-width;
}

.sidebar-brand {
  width: $sidebar-width;
  height: 85px;
  text-align: center;
  padding: 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-nav {
  width: $sidebar-width;
  margin: 0;
  padding: 0;
  list-style: none;
}
.sidebar-nav > li {
  text-indent: 5px;
  line-height: 42px;
}
.sidebar-nav > li a {
  display: block;
  text-decoration: none;
  color: var(--color-primary);
  font-size: 15.5px;
}
.sidebar-nav > li > a:hover,
.sidebar-nav > li.active > a {
  text-decoration: none;
  color: #fff;
  background: var(--color-primary);
  box-shadow: inset 0px 0.5px 0px 0px #fff;
}
.sidebar-nav > li > a i.fa {
  font-size: 18px;
  width: $sidebar-toggled-width;
}
.sidebar-nav {
  li {
    .orderLink {
      transition: 0.3s;
      .inversed {
        display: none;
      }
    }
  }
}
.sidebar-nav {
  li {
    .orderLink:hover {
      .inversed {
        display: inline;
      }
      .regular {
        display: none;
      }
    }
  }
}

#navbar-wrapper {
    width: 100%;
    position: absolute;
    z-index: 2;
}
#wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -$sidebar-width;
}
#navbar-wrapper .navbar {
  border-width: 0 0 0 0;
  font-size: 24px;
  margin-bottom: 0;
  border-radius: 0;
}

#content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
  top: 100px;
}
.toggled .logo-img, .toggled .version-info{
  display:none !important;
}
.version-info {
  color: var(--color-primary);
}
#wrapper.toggled #content-wrapper {
  position: absolute;
  margin-right: -$sidebar-width;
}

.sg-sidebar {
  overflow-y: auto;
  overflow-x: hidden;
}
#sidebar-wrapper .sg-sidebar {
  height: calc(100% - 8.6rem) !important;
}
.sidebar-footer {
  padding: 0.85rem 0.5rem;
}
.sidebar-footer {
  border-top: 1px solid #dee2e6;
}

@media (min-width: 1101px) {
  #wrapper  {
    padding-left: $sidebar-width;
  }
  
  #wrapper.toggled {
    padding-left: $sidebar-toggled-width;
  }

  #sidebar-wrapper {
    width: $sidebar-width;
  }
  
  #wrapper.toggled #sidebar-wrapper {
    width: $sidebar-toggled-width;
  }
  
  #wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -190px;
}
  
  #wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -190px;
  }

  #navbar-wrapper {
    position: relative;
  }

  #wrapper.toggled {
    padding-left: $sidebar-toggled-width;
  }

  #content-wrapper {
    position: relative;
    top: 0;
  }

  #wrapper.toggled #navbar-wrapper,
  #wrapper.toggled #content-wrapper {
    position: relative;
    margin-right: $sidebar-toggled-width;
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
  #wrapper  {
    padding-left: $sidebar-toggled-width;
  }

  #sidebar-wrapper {
    width: $sidebar-toggled-width;
  }
  #sidebar-wrapper .logo-img, #sidebar-wrapper .version-info {
    display: none !important;
  }
  .toggled #sidebar-wrapper .logo-img, .toggled #sidebar-wrapper .version-info{
    display:unset !important;
  }
  #wrapper.toggled #navbar-wrapper {
      position: absolute;
      margin-right: -$sidebar-width;
  }
  
  #wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -$sidebar-width;
  }

  #navbar-wrapper {
    position: relative;
  }

  #wrapper.toggled {
    padding-left: $sidebar-width;
  }

  #content-wrapper {
    position: relative;
    top: 0;
  }

  #wrapper.toggled #navbar-wrapper,
  #wrapper.toggled #content-wrapper {
    position: relative;
    margin-right: $sidebar-width;
  }
}

@media (max-width: 767px) {
  #wrapper  {
    padding-left: 0;
  }

  #sidebar-wrapper {
    width: 0;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: $sidebar-width;
  }
  #wrapper.toggled #navbar-wrapper {
    position: absolute;
    margin-right: -$sidebar-width;
  }

  .toggled #sidebar-wrapper .logo-img, .toggled #sidebar-wrapper .version-info{
    display:unset !important;
  }

  #wrapper.toggled #content-wrapper {
    position: absolute;
    margin-right: -$sidebar-width;
  }

  #navbar-wrapper {
    position: relative;
  }

  #wrapper.toggled {
    padding-left: $sidebar-width;
  }

  #content-wrapper {
    position: relative;
    top: 0;
  }

  #wrapper.toggled #navbar-wrapper,
  #wrapper.toggled #content-wrapper {
    position: relative;
    margin-right: $sidebar-width;
  }
} 
